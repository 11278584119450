.App {
  text-align: center;
}


form{ 
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin: 40px auto;
} 

input {
border: none;
border-bottom: 1px solid gray;
padding:5px;
}

button {

  border: none;
  background: greenyellow;
  padding: 5px;
  border-radius:5px;
  cursor:pointer;
  transition: all .2s ease;

}

button:hover {
  color:aliceblue;
  background-color: #61dafb;
  box-shadow: 0 5px 5px rgba(0,0,0,.5);
}






.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
